import * as React from 'react';
import Layout from '../layout';
import { AdditionalMessage, MessageForm, MessageFormContainer, MessageResumeVideo, MessageResumeVideoContainer, MessageWrapper, MessageWrapperContent, MessageWrapperHeader, PlanOption, PlanSelect, PlanSelectElement, PlanSelectTitle, PlanWrapper, VideoMobilePreview, VideoMobilePreviewButton, VideoThumb, VideoThumbContainer } from './styled';


import { Button, Input, TextP, TitleH1, TitleH2, TitleH3 } from '../../widgets/base';
import { Checkbox, CheckboxGroup, CheckboxLabel, ErrorInfo, FieldsRequired, InputGroup, InputGroupElt, InputInfo, InputInfoIcon, Label, SendOption, SendSelect, SendSelectElement, TextArea } from '../../widgets/forms';
import { AlignRight, OnMobile, Space, StepNumber } from '../../widgets/layout';

import DatePicker from "react-datepicker";
import { colors } from '../../variables';

import { useNavigate, useParams } from 'react-router-dom';

import icoDate from './images/ico-date.svg';
import icoTime from './images/ico-time.svg';

import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import livstickApp from '../../models/livstick';

import { Observer } from 'mobx-react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import ConsentsContext from '../../context/consents';
import ScenesContext from '../../context/scenes';
import { events } from "../../events";
import MessageCheckbox from './widgets/checkbox';

import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { isAndroid } from 'react-device-detect';
import useWebsite, { WEBSITE } from '../../hooks/websiteHooks';
import useCode from '../../hooks/useCode';

import Parse from 'parse';

const videoJsOptions = {
  controls: true,
  bigPlayButton: true,
  fluid: true,
};

const range = (start, stop, step = 1) =>
  Array(Math.ceil((stop - start) / step)).fill(start).map((x, y) => x + y * step)

const EditPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { website } = useWebsite();
  const assets: any = website.assetsList;
  const [services, setServices] = React.useState(livstickApp.services ?? []) ;

  const concents: string[] = React.useContext(ConsentsContext);
  const countries: any[] = website.countries;
  const scenes: any = React.useContext(ScenesContext);

  const videoEl = React.useRef(null);
  const fileEl = React.useRef(null);
  const thumbVideo = React.useRef<HTMLVideoElement>(null)
  const [videoUri, setVideoUri] = React.useState(null)

  const [showMobileVideo, setShowMobileVideo] = React.useState(false);

  const params = useParams();
  const code = useCode(params.id);
  const [message, setMessage] = React.useState(null)

  const [firstname, setFirstname] = React.useState(null);
  const [lastname, setLastname] = React.useState(null);
  const [senderEmail, setSenderEmail] = React.useState(null);
  const [receiverEmail, setReceiverEmail] = React.useState(null);
  const [body, setBody] = React.useState(null);
  const [video, setVideo] = React.useState(null);
  const [player, setPlayer] = React.useState(null);

  const [loadIndicator, setLoadIndicator] = React.useState(0);

  const [sendTypeIndex, setSendTypeIndex] = React.useState<number>(0);

  React.useEffect(() => {
    if (code) {
      setMessage(code.get('message'));
      console.log('message', code.get('message'))
      setFirstname(code.get('message').get('data').firstname);
      setLastname(code.get('message').get('data').lastname);
      setBody(code.get('message').get('content'));
      setSenderEmail(code.get('message').get('data').senderEmail);
      setReceiverEmail(code.get('message').get('data').receiverEmail);
    }
    
    fileEl.current.addEventListener('change', (e) => {
      const file = fileEl.current.files[0];
      if ((file.size / (1024*1024)) > 30 ) return alert(intl.formatMessage({id: "player_txt_warning_toobig"}));
      if (!file.type.includes('video') ) return alert(intl.formatMessage({id: "player_txt_warning_format"}));
      setVideo(file);
      setShowMobileVideo(false);
    });
  }, [code]);

  const init = async () => {
    setServices(await Parse.Cloud.run('getServicesV2', {
      platformId: WEBSITE.platform.id,
      urid:  code.get('urid')
    }));
  }

  React.useEffect(() => {
    init()
    console.log('ref', videoEl)
    // var video = thumbVideo.current;
    // if (video) {
    //   video.addEventListener('loadeddata', function () {
    //     this.currentTime = 0;
    //   }, false);

    //   video.load();
    // }

    if (message) console.log("contentFile", message.get('contentFile').url());
    if (message) var _player = videojs(videoEl.current, videoJsOptions, () => {
      const version_info = 'Using video.js ' + videojs.VERSION;
      videojs.log(version_info);
      var fileUrl = message.get('contentFile').url();
      var type = video?.type;
      _player.src({ type, src: video ? URL.createObjectURL(video) : fileUrl });
      _player.load();
      //_player.play();
      _player.loop();
    });
    setPlayer(_player)


  }, [message])

  React.useEffect(() => {
    if (video) {
      const type = video.type;
      player.src({ type, src: URL.createObjectURL(video) });
      player.load();
    }
  }, [video])

  /* Checlbox CGU */
  const [isCGUToggled, setCGUToggled] = React.useState<boolean>(false);
  const toggleCGUTrueFalse = () => setCGUToggled(!isCGUToggled);

  /* Preview Mobile / Tablette */
  const [PreviewActive, setPreviewActive] = React.useState<boolean>(false);
  const openPreview = () => { setPreviewActive(true); }
  const closePreview = () => { setPreviewActive(false); }

  /* Info Input */
  const [InfoActive, setInfoActive] = React.useState<boolean>(false);
  const openInfo = () => { setInfoActive(true); }
  const closeInfo = () => { setInfoActive(false); }

  const [error, setError] = React.useState(false);
  const [error2, setError2] = React.useState(false);
  const [error3, setError3] = React.useState(false);

  const [planificationDate, setPlanificationDate] = React.useState<Date>(moment().add(2, 'days').toDate());
  const [planificationTime, setPlanificationTime] = React.useState<string>("9");


  React.useEffect(() => {
    if (services && services.includes('front_schedule_form')) {
      let _date = new Date(planificationDate.valueOf());
      if (_date) {
        _date.setHours(parseInt(planificationTime), 0, 0, 0);
        console.log(_date);
        livstickApp.planification = _date;
      }
    }
  }, [planificationDate, planificationTime])

  const goToMessage = () => {
    fileEl.current.click();
  }

  const goToRecord = () => {
    fileEl.current.click();
  }

  const editMessage = async () => {
    message.set('data', {...message.get('data'), ...{
      firstname, lastname
    }});
    message.set('content', body);
    if (video) {
      let file = new Parse.File('video.mp4', new File([video], 'video.mp4'));
      await file.save({
        progress: (progressValue, loaded, total, { type }) => {
          if (type === "upload" && progressValue !== null) {
            // Update the UI using progressValue
            setLoadIndicator(Math.round(progressValue * 100));
            if (progressValue == 1) navigate('/end');
          }
        }
      });

      message.set('contentFile', file);
      await message.save(null, {});
    }else {
      await message.save(null, {});
      navigate('/end');
    }
  }

  return <Layout>
    <Observer>{() =>
      <MessageWrapper className="page-message">
        <input type="file" style={{display: 'none'}} ref={fileEl} accept="video/*" />
        <MessageWrapperHeader>
          <TitleH1 dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "edit_txt_title" }) }}></TitleH1>
        </MessageWrapperHeader>
        <MessageWrapperContent>
          <MessageResumeVideoContainer className="block-preview">
            <MessageResumeVideo>
              <video id="myVideo" ref={videoEl} className="video-js vjs-default-skin" playsInline></video>
            </MessageResumeVideo>
            <Button color={colors.secondary} hover="secondary" onClick={goToMessage}><FormattedMessage id="player_txt_button_retry" /></Button>
          </MessageResumeVideoContainer>


          <MessageFormContainer className="block-form">
            <MessageForm>
              <OnMobile>
                {services && !isAndroid && services.includes('review_video_mobile_v2') ?
                  <div>
                  <VideoThumbContainer onClick={() => setShowMobileVideo(!showMobileVideo)}>
                    <img src={videoUri} />
                    <VideoThumb><video ref={thumbVideo} preload="metadata" src={message.get('contentFile').url()}></video></VideoThumb>
                    <TitleH2 dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "form_txt_review_video" }) }} ></TitleH2>
                  </VideoThumbContainer>
                  <TitleH3 dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "form_txt_title_form" }) }} ></TitleH3>
                  </div> :
                  <TitleH2 dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "form_txt_title_form" }) }} ></TitleH2>}
              </OnMobile>
              <TitleH3><FormattedMessage id="form_txt_from" /></TitleH3>
              {services && services.includes('front_with_custom_message_form') ?
                <>
                  <InputGroup>
                    <InputGroupElt>
                      <Label><FormattedMessage id="form_txt_from_gender_label"></FormattedMessage></Label>
                      <SendSelect
                        onChange={e => livstickApp.additionalData['gender'] = e.target.value}
                        placeholder={intl.formatMessage({ id: "form_txt_from_gender" })}>
                        <option disabled selected hidden>{intl.formatMessage({ id: "form_txt_from_gender" })}</option>
                        <option value="m">{intl.formatMessage({ id: "form_txt_from_gender_m" })}</option>
                        <option value="f">{intl.formatMessage({ id: "form_txt_from_gender_f" })}</option>
                      </SendSelect>
                    </InputGroupElt>
                    <InputGroupElt>
                      <Label><FormattedMessage id="form_txt_from_fname_label"></FormattedMessage></Label>
                      <Input placeholder={intl.formatMessage({ id: "form_txt_from_fname" })} value={firstname} onChange={e => setFirstname(e.target.value)}></Input> {/* variable : form_txt_from_fname */}
                    </InputGroupElt>
                  </InputGroup>
                  <InputGroup>
                    <InputGroupElt>
                      <Label><FormattedMessage id="form_txt_from_lname_label"></FormattedMessage></Label>
                      <Input placeholder={intl.formatMessage({ id: "form_txt_from_lname" })} value={lastname} onChange={e => setLastname(e.target.value)}></Input> {/* variable : form_txt_from_lname */}
                    </InputGroupElt>
                    <InputGroupElt>
                      <Label><FormattedMessage id="form_txt_from_country_label"></FormattedMessage></Label>
                      <SendSelect
                        onChange={e => livstickApp.additionalData['country'] = e.target.value}
                      >
                        <option disabled selected>{intl.formatMessage({ id: "form_txt_from_country" })}</option>
                        {countries.map(_c => <option value={_c.get('uid')}>{_c.get('content')}</option>)}
                      </SendSelect>
                    </InputGroupElt>
                  </InputGroup>
                  <InputGroup>
                    <InputGroupElt>
                      <Label><FormattedMessage id="form_txt_from_email_label"></FormattedMessage></Label>
                      <Input placeholder={intl.formatMessage({ id: "form_txt_from_email" })} value={receiverEmail} onChange={e => setReceiverEmail(e.target.value)}></Input> {/* variable : form_txt_from_email */}
                      <InputInfoIcon onMouseEnter={() => openInfo()} onMouseLeave={() => closeInfo()} active={InfoActive == true}></InputInfoIcon>
                      <InputInfo className="message-info" active={InfoActive == true}><FormattedMessage id="form_txt_to_reinsurance_email" /></InputInfo>
                    </InputGroupElt>
                  </InputGroup>
                </>
                :
                <>
                  <InputGroup>
                    <InputGroupElt>
                      <Label><FormattedMessage id="form_txt_from_fname_label"></FormattedMessage></Label>
                      <Input placeholder={intl.formatMessage({ id: "form_txt_from_fname" })} value={firstname} onChange={e => setFirstname(e.target.value)}></Input> {/* variable : form_txt_from_fname */}
                    </InputGroupElt>
                    <InputGroupElt>
                      <Label><FormattedMessage id="form_txt_from_lname_label"></FormattedMessage></Label>
                      <Input placeholder={intl.formatMessage({ id: "form_txt_from_lname" })} value={lastname} onChange={e => setLastname(e.target.value)}></Input> {/* variable : form_txt_from_lname */}
                    </InputGroupElt>
                  </InputGroup>
                  <InputGroup>
                    <InputGroupElt>
                      <Label><FormattedMessage id="form_txt_from_email_label"></FormattedMessage></Label>
                      <Input placeholder={intl.formatMessage({ id: (services && services.includes('front_email_optional')) ? "form_txt_from_email_optionnal" : "form_txt_from_email" })} value={senderEmail} onChange={e => setSenderEmail(e.target.value)} disabled></Input> {/* variable : form_txt_from_email */}
                      <InputInfoIcon className="message-info-icon" onMouseEnter={() => openInfo()} onMouseLeave={() => closeInfo()} active={InfoActive == true}></InputInfoIcon>
                      <InputInfo className="message-info" active={InfoActive == true}><FormattedMessage id={(services && services.includes('front_email_optional')) ? "form_txt_to_reinsurance_email_optional" : "form_txt_to_reinsurance_email"} /></InputInfo>
                    </InputGroupElt>
                  </InputGroup>
                </>}

              {error && <ErrorInfo><FormattedMessage id="form_txt_error1" /></ErrorInfo>}
              {error3 && <ErrorInfo><FormattedMessage id="form_txt_error3" /></ErrorInfo>}

              {services && services.includes('receiver_data_form') && !livstickApp.answer && <>
                <Space />
                <TitleH3><FormattedMessage id="form_txt_to" /></TitleH3>
                <TextP><FormattedMessage id="form_txt_to_reinsurance" /></TextP>
                <SendSelectElement className="select-group">
                  <SendSelect onChange={e => {
                    if (e.currentTarget.value == "email") {
                      setSendTypeIndex(0)
                    } else if (e.currentTarget.value == "phone") {
                      setSendTypeIndex(1)
                    } else {
                      setSendTypeIndex(2)
                    }
                  }} disabled>
                    {services && services.includes('receiver_data_form_with_email') && <SendOption value="email">{intl.formatMessage({ id: 'form_txt_to_bymail' })}</SendOption>}
                    {services && services.includes('receiver_data_form_with_phone') && <SendOption value="phone">{intl.formatMessage({ id: 'form_txt_to_byphone' })}</SendOption>}
                  </SendSelect>
                  {sendTypeIndex == 0 && <Input placeholder={intl.formatMessage({ id: "form_txt_to_email" })} value={receiverEmail} onChange={e => setReceiverEmail(e.target.value)} disabled></Input>}
                  {sendTypeIndex == 1 && <PhoneInput
                    country={scenes.receiverDataPhoneDefaultCountry}
                    preferredCountries={scenes.receiverDataPhonePreferredCountries}
                    onChange={phone => livstickApp.phone = phone}
                  />}

                </SendSelectElement>
                {/* <FieldsRequired><FormattedMessage id="form_txt_to_reinsurance_sms" /></FieldsRequired> */}
              </>}


              {services && services.includes('front_with_text_message') && <>
                <AdditionalMessage className="additional-message">
                  <TitleH3><FormattedMessage id="form_txt_add_message_title" /></TitleH3>
                </AdditionalMessage>
                <InputGroup>
                  <InputGroupElt>
                    <Label><FormattedMessage id="form_txt_add_message_label"></FormattedMessage></Label>
                    <TextArea placeholder={intl.formatMessage({ id: "form_txt_add_message_placeholder" })} value={body} onChange={e => setBody(e.target.value)}></TextArea>
                  </InputGroupElt>
                </InputGroup>
              </>}





              {services && services.includes('front_schedule_form') && !livstickApp.answer && <>
                <AdditionalMessage className="additional-message">
                  <TitleH3><FormattedMessage id="form_txt_schedule_title" /></TitleH3>

                </AdditionalMessage>
                <TextP><FormattedMessage id="form_txt_schedule_title_info" /></TextP>
                <PlanWrapper className="schedule-wrapper" active={true}>
                  <PlanSelectElement>
                    <PlanSelectTitle className="title-date" ><img src={icoDate} className="img-label" /><TitleH3> <FormattedMessage id="form_txt_schedule_date" defaultMessage="Date" /></TitleH3></PlanSelectTitle>
                    <InputGroup className="group-date">
                      <DatePicker locale="fr" dateFormat="dd/MM/yyyy" selected={planificationDate} withPortal onChange={e => setPlanificationDate((e as Date))} filterDate={(date) => {
                        return moment().startOf('day').add('2', 'days').toDate() <= date;
                      }} />
                    </InputGroup>
                  </PlanSelectElement>
                  <PlanSelectElement>
                    <PlanSelectTitle className="title-date"><img src={icoTime} className="img-label" /> <TitleH3><FormattedMessage id="form_txt_schedule_time" defaultMessage="Heure" /></TitleH3></PlanSelectTitle>
                    <PlanSelect onChange={(e) => setPlanificationTime(e.target.value)}>
                      <PlanOption value={"9"} selected={planificationTime == "9"}>09:00</PlanOption>
                      <PlanOption value={"12"} selected={planificationTime == "12"}>12:00</PlanOption>
                      <PlanOption value={"15"} selected={planificationTime == "15"}>15:00</PlanOption>
                      <PlanOption value={"18"} selected={planificationTime == "18"}>18:00</PlanOption>
                    </PlanSelect>
                  </PlanSelectElement>
                </PlanWrapper>
              </>}

              {services && services.includes('front_with_consents_title') && <>
                <TextP className="privacy-text" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'form_txt_warning_privacy' }) }}></TextP>
              </>}

              <CheckboxGroup onClick={toggleCGUTrueFalse} active={isCGUToggled == true}>
                <Checkbox />
                <CheckboxLabel dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'form_txt_checkbox1' }) }}></CheckboxLabel>
              </CheckboxGroup>

              {services && services.includes('front_with_additional_consents') && <>
                {concents.map(_c => <MessageCheckbox translationId={`form_txt_checkbox_${_c}`} consentKey={`consents_${_c}`} />)}
              </>}


              {error2 && <ErrorInfo><FormattedMessage id="form_txt_error2" defaultMessage="Error 2" /></ErrorInfo>}

              <AlignRight className="button-position-form">
                {loadIndicator == 0 &&<Button color={colors.primary} hover="primary" onClick={editMessage}><FormattedMessage id="form_txt_send" /></Button>}
                {loadIndicator > 0 && <TextP>Cargement : {loadIndicator}% </TextP>}

              </AlignRight>
              <FieldsRequired className="fields-required"><FormattedMessage id="form_txt_mandatory" /></FieldsRequired>

            </MessageForm>
          </MessageFormContainer>
        </MessageWrapperContent>

      </MessageWrapper>
    }</Observer>
    {showMobileVideo && <VideoMobilePreview>
      <div className='container'><video preload="metadata" src={video ? URL.createObjectURL(video) : message.get('contentFile').url()} controls playsInline></video></div>
      <div className="close" onClick={() => setShowMobileVideo(false)}></div>
      <VideoMobilePreviewButton onClick={goToRecord}><FormattedMessage id="player_txt_button_retry" /></VideoMobilePreviewButton>
    </VideoMobilePreview>}
  </Layout>
}

export default EditPage;