import { useEffect, useState } from "react";
import Parse from 'parse';

class Website {

  platform: any;
  services: any;
  languages: any;
  language: any;
  currentLanguage: any;
  init = async (setPending:any) => {
    const platformQuery = new Parse.Query("Platform");
    platformQuery.equalTo('domains', window.location.host);
    let _platform = await platformQuery.first()

    // set platform
    this.platform = _platform;

    if (_platform.get('template')) _platform = await _platform.get('template').fetch();

    this.services = _platform.get('services');

    const languageQuery = _platform.relation('languages').query();
    languageQuery.ascending('uid');
    const _languages = await languageQuery.find()
    this.languages = _languages;
    let currentLanguage = _languages.find(_l => {
      return _l.get('uid') === (this.language || navigator.language.split(/[-_]/)[0]);
    });

    if (!currentLanguage) {
      currentLanguage = await _platform.get('defaultLanguage').fetch();
      this.language = currentLanguage.get('uid');
      this.currentLanguage = {code: currentLanguage.get('uid')};
    } else{
      this.currentLanguage = {code: currentLanguage.get('uid')};
    }

    this.currentLanguage.languageObject = currentLanguage;

    const translationDefaultQuery = new Parse.Query("Translation");
    translationDefaultQuery.equalTo('language', currentLanguage);
    translationDefaultQuery.equalTo('isDefault', true);

    const translationQuery = new Parse.Query("Translation");
    translationQuery.equalTo('language', currentLanguage);
    translationQuery.equalTo('platform', _platform);
    const rawTranslations = {}

    await (await translationDefaultQuery.limit(200).find()).forEach(_t => {
      rawTranslations[_t.get('translationId')] = _t.get('content')
    });
    await (await translationQuery.limit(200).find()).forEach(_t => {
      rawTranslations[_t.get('translationId')] = _t.get('content')
    });

    if (_platform.get('services').includes('front_fetch_countries')) {
      const countryTranslationsQuery = new Parse.Query("CountryTranslation");
      countryTranslationsQuery.equalTo('language', currentLanguage);
      countryTranslationsQuery.ascending('content')
      countryTranslationsQuery.limit(300)
      const countries = await countryTranslationsQuery.find();
      this.countries = countries;
    }

    this.translations = rawTranslations;

    const assetsQuery = new Parse.Query("PlatformAsset");
    assetsQuery.equalTo('platform', _platform);
    let assets = await assetsQuery.find();

    const stylesQuery = new Parse.Query("PlatformStyleProperty");
    stylesQuery.equalTo('platform', _platform);
    stylesQuery.doesNotExist('languagesRestriction');
    let styles = await stylesQuery.find();

    const stylesLangQuery = new Parse.Query("PlatformStyleProperty");
    stylesLangQuery.equalTo('platform', _platform);
    stylesLangQuery.contains('languagesRestrictions', this.currentLanguage.code)
    let stylesLang = await stylesQuery.find();

    let theme = {};
    let assetsTheme = {};

    styles.forEach((item) => theme[item.get('key')] = item.get('value'));
    stylesLang.forEach((item) => theme[item.get('key')] = item.get('value'));
    assets.forEach((item) => assetsTheme[item.get('type')] = item.get('contentFile').url());

    this.theme = { ...theme, ...assetsTheme };
    this.assetsList = { ...theme, ...assetsTheme };
    this.loaded = true;
    setPending(false);
  }

  fetchTranslation = async (lang) => {
    const platformQuery = new Parse.Query("Platform");
    platformQuery.equalTo('domains', window.location.host);
    const _platform = await platformQuery.first()

    const languageQuery = _platform.relation('languages').query();
    languageQuery.ascending('uid');
    const _languages = await languageQuery.find()
    this.languages =_languages;

    const currentLanguage = _languages.find(_l => _l.get('uid') == lang);
    this.currentLanguage.languageObject = currentLanguage;
    this.currentLanguage.code = currentLanguage.get('uid');
    const translationDefaultQuery = new Parse.Query("Translation");
    translationDefaultQuery.equalTo('language', currentLanguage);
    translationDefaultQuery.equalTo('isDefault', true);

    const translationQuery = new Parse.Query("Translation");
    translationQuery.equalTo('language', currentLanguage);
    translationQuery.equalTo('platform', _platform);
    const rawTranslations = {}

    await (await translationDefaultQuery.limit(200).find()).forEach(_t => {
      rawTranslations[_t.get('translationId')] = _t.get('content')
    });
    console.log({ rawTranslations });
    await (await translationQuery.limit(200).find()).forEach(_t => {
      rawTranslations[_t.get('translationId')] = _t.get('content')
    });



    if (_platform.get('services').includes('front_fetch_countries')) {
      const countryTranslationsQuery = new Parse.Query("CountryTranslation");
      countryTranslationsQuery.equalTo('language', currentLanguage);
      countryTranslationsQuery.ascending('content')
      countryTranslationsQuery.limit(300)
      const countries = await countryTranslationsQuery.find();
      this.countries = countries;
    }

    const assetsQuery = new Parse.Query("PlatformAsset");
    assetsQuery.equalTo('platform', _platform);
    let assets = await assetsQuery.find();

    const stylesQuery = new Parse.Query("PlatformStyleProperty");
    stylesQuery.equalTo('platform', _platform);
    stylesQuery.doesNotExist('languagesRestriction');
    let styles = await stylesQuery.find();

    const stylesLangQuery = new Parse.Query("PlatformStyleProperty");
    stylesLangQuery.equalTo('platform', _platform);
    stylesLangQuery.contains('languagesRestriction', this.currentLanguage.code)
    let stylesLang = await stylesLangQuery.find();


    let theme = {};
    let assetsTheme = {};

    styles.forEach((item) => theme[item.get('key')] = item.get('value'));
    stylesLang.forEach((item) => theme[item.get('key')] = item.get('value'));
    assets.forEach((item) => assetsTheme[item.get('type')] = item.get('contentFile').url());



    this.theme = { ...theme, ...assetsTheme };
    this.assetsList = { ...theme, ...assetsTheme };

    this.translations = rawTranslations;
  }
  translations: any;
  countries: Parse.Object<Parse.Attributes>[];
  theme: any;
  assetsList: any;
  loaded = false;
}

export const WEBSITE = new Website();

const useWebsite = () => {
  const [website, setWebsite] = useState(WEBSITE);
  const [isPending, setPending] = useState(false);

  const setLanguageWebsite = async (lang) => {
    if(website) {
      setPending(true);
      await website.fetchTranslation(lang);
      setPending(false)
    }
  }

  useEffect(() => {
   if(!website.loaded){
    setPending(true);
    website.init(setPending);
   }
  }, [])
  return {website, isPending, setLanguageWebsite};
}

export default useWebsite;