import styled, { css } from "styled-components";
import icoInfo from './../images/ico-info.svg';
import icoArrowDown from './../images/ico-down.svg';
import icoArrowDownWhite from './../images/ico-down-white.svg';
import icoCheck from './../images/ico-check.svg';
import icoDate from './../images/ico-calendar.svg';

export const Form = styled.div`
  padding: 20px;
  padding-top: 0px;
  font-family: ${props => props.theme.fontFamilyText || 'Arial, Helevetica, sans-serif'};
  &.form-landing{
    button{
      width: 100%;
    }
  }
  @media (max-width: 767px){
    padding: 20px;
    padding-top: 0px;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const InputGroupElt = styled.div`
  display: block;
  position: relative;
  width: 100%;
  flex-grow: 1;
  flex-basis: 0;
  margin: 5px;
`;

export const InputInfoIcon = styled.div<{active?: boolean}>`
  position: absolute;
  right: 20px;
  top: 16px;
  background-image: url(${icoInfo});
  background-size: 20px auto;
  background-position: center center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  opacity: 1;
  right: ${props => props.theme.rtl == 'true' ? "inherit" : "20px"};
  left: ${props => props.theme.rtl == 'true' ? "20px" : "inherit"};
  @media (max-width: 991px){
    display: none;
  }
  cursor: pointer;
  ${props => props.active && css`
    opacity: 1;
  `}
`;
export const InputInfo = styled.div<{active?: boolean}>`
  position: absolute;
  right: 0px;
  top: 50px;
  background-color: #909090;
  width: 200px;
  padding: 20px;
  border-radius: 10px;
  font-family: ${props => props.theme.fontFamilyText || 'Arial, Helevetica, sans-serif'};
  font-size: 11px;
  color: #fff;
  z-index: 9999;
  -webkit-box-shadow: 0px 0px 56px 8px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 0px 56px 8px rgba(0,0,0,0.25);
  box-shadow: 0px 0px 56px 8px rgba(0,0,0,0.25);
  
  &:after{
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    top: -10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid #909090;
    right: 17px;
    margin-left: 15px;
  }
  display: none;
  ${props => props.active && css`
    display: block;
  `}
`;

export const ErrorInfo = styled.p`
  color: red;
  font-size: 11px;
  padding: 0px 0px;
  margin: 0px;
  font-family: ${props => props.theme.fontFamilyText || 'Arial, Helevetica, sans-serif'};
`;

export const TextArea = styled.textarea`
  font-family: ${props => props.theme.fontFamilyText || 'Arial, Helevetica, sans-serif'};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 15px 20px;
  border-radius: ${props => props.theme.inputRadius || '0.25rem'};
  font-size: ${props => props.theme.inputFontSize || '14px'};
  background: ${props => props.theme.inputBackgroundColor || '#fff'};
  border-width: ${props => props.theme.inputBorder || '0px'};
  border-style: solid;
  border-color: ${props => props.theme.inputBorderColor || 'transparent'};
  color: ${props => props.theme.inputColor || '#000'};
  box-sizing: border-box;
  height: 150px;
  width: 100%;
  display: block;
  position: relative;
  
  &::placeholder {
    color: #909090;
    
  }
  &:focus{
    outline: inherit;
  }
`;


export const SendSelectElement = styled.div`
  width: 100%;
  margin: 0px;
  display: flex;
  margin-bottom: 10px;
  @media (max-width: 767px){
    margin-bottom: 15px;
    display: block;
    input{
      box-sizing: border-box;
    }
  }
  &:first-child{
    margin-left: 0px;
  }
  &:last-child{
    margin-right: 0px;
    width: 150px;
    @media (max-width: 767px){
      width: 100%;
      
    }
  }
`;
export const SendSelect = styled.select`
    background-size: 10px auto;
    background-position: 92%;
    background-image: url('${props => props.theme.selectBackgroundImage}');
    background-repeat: no-repeat;
    font-family: ${props => props.theme.fontFamilyText || 'Arial, Helevetica, sans-serif'};
    border-radius: ${props => props.theme.inputRadius || '0.25rem'};
    font-size: ${props => props.theme.inputFontSize || '14px'};
    background-color: ${props => props.theme.selectBackgroundColor || '#fff'};
    border-width: ${props => props.theme.inputBorder || '0px'};
    border-style: solid;
    font-weight: ${props => props.theme.selectFontWeight || 'bold'};
    border-color: ${props => props.theme.inputBorderColor || 'transparent'};
    border-right: 0px;
    color: ${props => props.theme.selectColor || '#000'};

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: auto;
    height: 50px;
    min-width: 180px;
    position: relative;
    padding: 15px 20px;
    flex-grow: 0.5;
    flex-basis: 0;
    @media (max-width: 991px){
      font-size: 12px;
      border-right: 1px solid ${props => props.theme.inputBorderColor || 'transparent'};
      border-bottom: 0px;
    }
    @media (max-width: 767px){
      width: 100%;
    }
    &:focus{
      outline: inherit;
    }
    &:before{
      
    }
    
`;
export const SendOption = styled.option`
  
`;



export const Terms = styled.p`
  font-size: 11px;
  color: #000;
  
  line-height: 16px;
  padding-left: 40px;
  a{
    text-decoration: underline;
    color: #000;
  }
`;

export const Label = styled.div`
  position: absolute;
  left: 10px;
  top: -2px;
  padding: 0px 10px;
  font-size: 12px;
  font-weight: bold;
  z-index: 20;
  background-color: #fff;
  color: #000;
  display: none;
  font-family: ${props => props.theme.fontFamilyText || 'Arial, Helevetica, sans-serif'};
  span{
    font-style: italic;
    font-weight: normal;
  }

  `;
  export const LabelTitle = styled.div`
  
  font-size: 14px;
  font-weight: bold;
  color: #000;
  font-family: ${props => props.theme.fontFamilyText || 'Arial, Helevetica, sans-serif'};
  span{
    font-style: italic;
    font-weight: normal;
  }

  `;



export const CheckboxGroup = styled.div<{active?: boolean}>`
  display: flex;
  margin: 5px;
  margin-bottom: 10px;
  margin-top: 20px;
  align-items: center;
  flex-direction: ${props => props.theme.rtl == 'true' ? "row-reverse" : "row"};
  cursor: pointer;
  ${props => props.active && css`
    > div{
      background-color: ${props => props.theme.checkboxActiveBackground || props.theme.buttonPrimaryBackgroundColor || '#0d6efd'};
      border: 1px solid ${props => props.theme.checkboxActiveBorder || props.theme.buttonPrimaryBackgroundColor || '#0d6efd'};
      background-image: url(${icoCheck});
      background-size: 16px auto;
      background-position: center center;
      background-repeat: no-repeat;
    }
  `}
`;
export const Checkbox = styled.div`
  width: 22px;
  height: 22px;
  border-radius: ${props => props.theme.checkboxRadius || '4px'};
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  display: inline-block;
  flex-shrink: 0;
  @media (max-width: 767px){
    width: 15px;
    height: 15px;
  }
  
`;
export const CheckboxLabel = styled.span`
  font-size: ${props => props.theme.checkboxFontSize || '11px'};
  line-height: 16px;
  color: #000;
  font-family: ${props => props.theme.fontFamilyText || 'Arial, Helevetica, sans-serif'};
  margin-left: ${props => props.theme.rtl == 'true' ? "0px" : "15px"};
  margin-right: ${props => props.theme.rtl == 'true' ? "15px" : "0px"};
  text-transform: initial;
  @media (max-width: 991px){
    font-size: 11px;
  }
  a{
    text-decoration: underline;
    color: #000;
    @media (max-width: 991px){
      
    }
    &::hover, &:focus{
      color: #000;
    }
  }
`;




export const FieldsRequired = styled.p`
  color: ${props => props.theme.PColor || 'grey'};
  font-style: ${props => props.theme.fontRequiredItalic || 'italic'};
  font-size: 11px;
  padding: 0px 0px;
  margin: 0px;
  margin-top: 20px;
  text-align: ${props => props.theme.rtl == 'true' ? "right" : "left"};
  font-family: ${props => props.theme.fontFamilyText || 'Arial, Helevetica, sans-serif'};
  @media (max-width: 991px){
    
  }
`;

export const InputDateIcon = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  background-image: url(${icoDate});
  background-size: 20px auto;
  background-position: center center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  opacity: 0.4;
  @media (max-width: 991px){
    background-image: inherit;
  }
  @media (max-width: 767px){
    top: 60px;
  }
`;
