import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Parse from "parse";
import { WEBSITE } from "./websiteHooks";

const useCode = (base, website_default?:boolean) => {
  const [code, setCode] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      
      if(!code){
        if(website_default){
          let code = await WEBSITE.platform.get('defaultCode').fetch();
          if(!code) navigate("/")
          await code.get("message")?.fetch();
          setCode(code);
        }
        else{
          const query = new Parse.Query("Code");
          query.equalTo('platform', WEBSITE.platform);
          query.equalTo('urid', base.toLowerCase());
          let code = await query.first();
          if(!code) navigate("/");
          await code.get("message")?.fetch();

          if(window.location.pathname.startsWith("/answer")){
            //ici on triche pour avoir le message de réponse sans modification de code sur la page de lecture.
            code.set("message", await code.get("message").get('answeredBy').fetch());
          }
          setCode(code);
        }
      }
    })()
    
  }, [base])


  return code;
}

export default useCode;