import styled, { css } from "styled-components";
import { colors } from "../../variables";

import icoCross from "./images/ico-cross.svg";


export const MessageWrapperContent = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: top;
  flex-direction: ${props => props.theme.rtl == 'true' ? "row-reverse" : "row"};
  @media (max-width: 991px){
      margin: 20px 0px
    }
`;

export const MessageWrapperHeader = styled.div`
  text-align: center;
`;

export const MessageWrapper = styled.div`
  width: 980px;
  position: relative;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  
  .only-mobile{
    display: none;
    @media (max-width: 991px){
      display: block;
    }
  }

  .react-datepicker-wrapper{
    display: none;
    @media (min-width: 992px){
      display: block;
    }
  }

  .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
    background-color: #B4985A;
  }
  @media (max-width: 767px){
    .react-datepicker, .react-datepicker__month-container, .react-datepicker-popper{
      width: 100%;
    }
    .react-datepicker__header select{
      width: 100%!important;
      padding-left: inherit!important;
    }
  }
  
  .react-datepicker__header{
    select{
      height: 25px;
    }
  }
  @media (max-width: 991px){
    margin-top: 30px;
    width: 100%;
    display: block;
  }
  
  @media (max-width: 767px){
    width: 98%;
    margin: 0 auto;
    margin-top: 30px;
  }
`;

export const MessageResumeVideoContainer = styled.div`
  width: 350px;
  text-align: ${props => props.theme.rtl == 'true' ? "left" : "right"};
  position: relative;
  
  @media (max-width: 991px){
    display: none;
  }
`;

export const MessageFormContainer = styled.div`
  width: 500px;
  border-radius: ${props => props.theme.ContainerRadius || '5px'};
  background-color: ${props => props.theme.messageBackground || '#fff'};
  z-index: 30;

  .select-group{
    margin: 5px;
    width: auto;
  }
  .react-tel-input{
    width: 100%;
    display: flex;
    .selected-flag{
      @media (max-width: 767px){
        padding-left: 20px;
      }
    }
    .flag-dropdown{
      border-radius: 0px;
      background-color: #fff;
      border-right: 0px;
    }
    input.form-control{
      font-family: ${props => props.theme.fontFamilyText || 'Arial, Helevetica, sans-serif'};
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0px;
      margin-left: 38px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      padding: 15px 20px;
      padding-left: 5px;
      border-radius: ${props => props.theme.inputRadius || '0.25rem'};
      font-size: ${props => props.theme.inputFontSize || '14px'};
      background: ${props => props.theme.inputBackgroundColor || '#fff'};
      border-width: ${props => props.theme.inputBorder || '0px'};
      border-style: solid;
      border-color: ${props => props.theme.inputBorderColor || 'transparent'};
      color: ${props => props.theme.inputColor || '#000'};
      height: 50px;
      display: block;
      position: relative;
      flex-grow: 1;
      box-sizing: border-box;
      width: 100%;
      flex-basis: 0;
      @media (max-width: 767px){
        padding-left: 20px;
      }
      &::placeholder {
        color: ${props => props.theme.inputPlaceHolderColor || 'grey'};
      }
      &.error{
        border: 1px solid red;
      }
      span{
        position: absolute;
        right: 5px;
      }
      &:focus{
        outline: inherit;
      }
    }
  }


  @media (max-width: 991px){
    width: 95%;
    margin: 0 auto;
    left: inherit;
    margin-top: 0px;
    right: inherit;
    margin-right: inherit;
    position: relative;
  }
  @media (max-width: 767px){
   position: relative;
   left: inherit;
   margin-left: inherit;
   width: 95%;
  }
`;

export const MessageResumeVideo = styled.div`
  background-color: #000;
  width: 350px;
  border-radius: 0px;
  overflow: hidden;
  position: relative;
  height: 220px;
  
  margin: 0 auto;
  margin-top: 10px;
  border: 1px solid #aaa;
  @media (max-width: 991px){
    border: 0px;
  }
  @media (max-width: 767px){
    width: 100%;
    height: 300px;
  }
`;

export const MessageForm = styled.div`
  padding: 20px 40px;
  @media (max-width: 991px){
    @media (max-width: 767px){
      padding: 20px;
    }
  }
  
`;

export const AdditionalMessage = styled.div<{active?: boolean}>`
  position: relative;
  align-items: top;
  margin: 0px;
  margin-top: 20px;
  padding: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  overflow: hidden;
  display: flex;
  justify-content: ${props => props.theme.rtl == 'true' ? "flex-end" : "flex-start"};
`;

export const PlanWrapper = styled.div<{active?: boolean}>`
  display: none;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 5px;

  .react-datepicker__input-container {

  }

  @media (max-width: 767px){
    &.schedule-wrapper{
      display: block;
    }
  }

  input {
    font-family: ${props => props.theme.fontFamilyText || 'Arial, Helevetica, sans-serif'};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 15px 20px;
  border-radius: ${props => props.theme.inputRadius || '0.25rem'};
  font-size: ${props => props.theme.inputFontSize || '14px'};
  background: ${props => props.theme.inputBackgroundColor || '#fff'};
  border-width: ${props => props.theme.inputBorder || '0px'};
  border-style: solid;
  border-color: ${props => props.theme.inputBorderColor || 'transparent'};
  color: ${props => props.theme.inputColor || '#000'};
  height: 50px;
  display: block;
  position: relative;
  flex-grow: 1;
  box-sizing: border-box;
  width: 100%;
  flex-basis: 0;
  text-align: ${props => props.theme.rtl == 'true' ? "right" : "left"};
  &::placeholder {
    color: ${props => props.theme.inputPlaceHolderColor || 'grey'};
  }
  &.error{
    border: 1px solid red;
  }
  span{
    position: absolute;
    right: 5px;
  }
  &:focus{
    outline: inherit;
  }
  }
  .group-date > div{
    width: 100%;
    display: flex;
  }
  .title-date{
    margin-left: 0px;
    align-items: center;
  }
  .title-date h3{
    margin-bottom: 0px;
  }
  .img-label{
    margin-right: 5px;
    height: 15px;
    width: auto;
  }
  
  @media (max-width: 991px){
    .group-date{
      display: block;
    }
  }
  ${props => props.active && css`
    display: flex;
    @media (max-width: 767px){
      display: block;
    }
  `}
`;
export const PlanSelectTitle = styled.div`
  color: #000;
  font-size: 11px;
  font-weight: normal;
  margin-bottom: 5px;
  align-items: center;
  display: flex;
  //padding-left: 5px;
  margin-left: -5px ;
  font-family: ${props => props.theme.fontFamilyText || 'Arial, Helevetica, sans-serif'};
  @media (max-width: 991px){
    font-size: 9px;
    
  }
  img{
    height: 20px;
    margin-right: 10px;
    width: auto;
    @media (max-width: 991px){
      height: 15px;
    }
  }
`;
export const PlanSelectElement = styled.div`
  width: 100%;
  margin: 5px;
  margin-top: 10px;
  @media (max-width: 991px){
    input{
      margin: 0px;
    }
  }
  @media (max-width: 767px){
    margin-bottom: 15px;
  }
  &:first-child{
    margin-left: 0px;
    width: 80%;
    @media (max-width: 767px){
      width: 100%;
    }
  }
  &:last-child{
    margin-right: 0px;
    @media (max-width: 991px){
      width: 100%;
    }
    @media (max-width: 767px){
      margin: 0px ;
    }
  }
`;
export const PlanSelect = styled.select`
    font-family: ${props => props.theme.fontFamilyText || 'Arial, Helevetica, sans-serif'};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 15px 20px;
  border-radius: ${props => props.theme.inputRadius || '0.25rem'};
  font-size: ${props => props.theme.inputFontSize || '14px'};
  background: ${props => props.theme.inputBackgroundColor || '#fff'};
  border-width: ${props => props.theme.inputBorder || '0px'};
  border-style: solid;
  border-color: ${props => props.theme.inputBorderColor || 'transparent'};
  color: ${props => props.theme.inputColor || '#000'};
  height: 50px;
  display: block;
  position: relative;
  flex-grow: 1;
  box-sizing: border-box;
  width: 100%;
  flex-basis: 0;
  text-align: ${props => props.theme.rtl == 'true' ? "right" : "left"};
  &::placeholder {
    color: ${props => props.theme.inputPlaceHolderColor || 'grey'};
  }
  &.error{
    border: 1px solid red;
  }
  span{
    position: absolute;
    right: 5px;
  }
  &:focus{
    outline: inherit;
  }
    &:after{
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      right: 10px;
      top: 10px;
      background-size: 20px auto;
      background-position: center center;
      background-repeat: no-repeat;
    }
    
`;
export const PlanOption = styled.option`
  
`;

export const VideoThumbContainer = styled.div`
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;

  h2 {
    flex: 1;
    margin-bottom: 0;
  }
`;

export const VideoThumb = styled.div`
  display: flex;
  border-radius: 10px;
  height: 60px;
  width: 80px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
  background-color: black;

  video {
    position: relative;
    border-radius: 10px;
    width: 80px;
    height: auto;
  }
`

export const VideoMobilePreview = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  .container {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    video {
      height: 100vh;
      width: auto;
    }
  }
  .close {
    position: absolute;
    height: 50px;
    width: 50px;
    right: 20px;
    top: 20px;
    background-image: url(${icoCross});
    background-size: 40px auto;
    background-position: center center;
    background-color: white;
    z-index:5000;
  }
`

export const VideoMobilePreviewButton = styled.div`
  position: absolute;
  
  bottom: 20px;
  left: 20px;
  right: 20px;
  color: black;
  background-color: white;
  font-family: ${props => props.theme.fontFamilyText || 'Arial, Helevetica, sans-serif'};
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  padding: 20px;
`

