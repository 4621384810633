import axios from 'axios';
import manifest from '../manifest';

import Parse from 'parse';

import { observable } from 'mobx';
import moment from 'moment';

import * as Crypto from 'crypto-js';
import { lang } from '../variables';
import { WEBSITE } from '../hooks/websiteHooks';

export const language = observable({
  codeInt: 1,
  code: navigator.language.split(/[-_]/)[0],
  languageObject: null
});

export const context = observable({
  platformObject: null
})

export const progression = observable({
  value: 0,
});

Parse.initialize('livstick');
Parse.serverURL = process.env.PARSE_URL;

// const Platform = Parse.Object.extend('Platform');
// const PlatformAsset = Parse.Object.extend('PlatformAsset');
const Code = Parse.Object.extend('Code');
const Platform = Parse.Object.extend('Platform');
const Message = Parse.Object.extend('Message');
const UserEvent = Parse.Object.extend('UserEvent');



class LivstickApp {
  currentCountry: string;

  code: string;
  codeObject: Parse.Object;
  messageObject: Parse.Object;
  answer: boolean;
  videoFile: Blob;
  message: string;
  galleryTitle: string;
  timestamp: string;
  planification: Date;
  planificationTime: Boolean;
  email: string;
  phone: string;
  senderEmail: string;

  services: string[];

  additionalData: object = {};

  firstname: string;
  lastname: string;
  country: string;
  birth: string;
  checkbox2: number;

  UTM: boolean;
  UTM_source: string;
  UTM_medium: string;
  utm_campaign: string;

  onChangeLang: Function[];

  inProgress: boolean = false;
  uploaded: boolean = false;

  isMatch: boolean = false;
  isPreview: boolean = false

  uid: string;

  changeLang(lang) {
    var codes = {
      'fr': 1,
      'en': 2,
      'en-US': 2,
      'en-UK': 2,
      'es': 4,
      'de': 9,
      'it': 5,
      'zh': 12,
      'ja': 13,
    }
    language.codeInt = codes[lang] || 2;
    language.code = lang || 'en';
    this.onChangeLang.forEach(func => func(lang));
  }
  //create new state app field or overload code field
  async postEvent(event: string) {

    var hash = Crypto.SHA256(performance.now());
    var encode = hash.toString(Crypto.enc.Hex);
    const platformId = WEBSITE.platform.id;
    this.uid = localStorage.getItem("randToken") || "";

    if (!("randToken" in localStorage)) {

      for (let i = 0; this.uid.length < 32; i++) {
        var randChar = Math.floor(Math.random() * encode.length)
        this.uid += encode[randChar]
      }
      localStorage.setItem("randToken", this.uid);
    }
    const platformPointer = {
      __type: 'Pointer',
      className: 'Platform',
      objectId: platformId
    }



    const log = new UserEvent();
    log.set("platform", platformPointer);
    log.set("userAgent", navigator.userAgent.toString())
    log.set("event", event);
    if (this.codeObject) {
      const codePointer = {
        __type: 'Pointer',
        className: 'Code',
        objectId: this.codeObject.id
      }
      log.set("code", codePointer);
    }

    log.set("uid", this.uid);
    await log.save();
  }

  async getCountry() {
    let _res = await axios({
      method: 'GET',
      url: `/getCountry`,
    });

    this.currentCountry = _res.data.iso_code;
    return _res.data;
  }

  async triage(addFirstView: boolean = false, fetchAnswer: boolean = false, overwrite: boolean = false, navigate?: any) {
    const query = new Parse.Query(Code);
    query.equalTo('platform', WEBSITE.platform);
    query.equalTo('urid', this.code.toLowerCase());
    let code = await query.first();

    if (WEBSITE.platform.get('services').includes('fallback_legacy')) {
      const fallback_payload = await axios.get(`/triage/?code=${this.code}&reseller=${WEBSITE.platform.get('servicesSettings').fallbackId}`)
      if (fallback_payload.data.ws == "pull_text") {
        if (WEBSITE.platform.get('servicesSettings').fallbackDomain == "fm2x.me") return window.location.href = `https://fm2x.me/liv3/show.php?code=${this.code}&reseller=${WEBSITE.platform.get('servicesSettings').fallbackId}#!/visualisation`;
        else return window.location.href = `https://${WEBSITE.platform.get('servicesSettings').fallbackDomain}/${WEBSITE.platform.get('servicesSettings').fallbackDomain}/${this.code}`;
      }
    }

    if (code === undefined) {
      if (WEBSITE.platform.get('services').includes('default_message') && !overwrite) {
        return navigate("/default/message")

      } else {
        code = new Code();
        code.set('urid', this.code.toLowerCase());
        code.set('platform', WEBSITE.platform);
        await code.save();
      }
    }

    this.codeObject = code;

    if (code.get('status') > 0) {
      livstickApp.messageObject = await code.get('message').fetch();
      if (fetchAnswer) livstickApp.messageObject = await livstickApp.messageObject.get('answeredBy').fetch();
      if (livstickApp.messageObject && addFirstView && !fetchAnswer) Parse.Cloud.run('triggerMessageView', { messageId: livstickApp.messageObject.id })
    }

    this.services = await Parse.Cloud.run('getServicesV2', {
      platformId: WEBSITE.platform.id,
      urid: this.code.toLowerCase()
    });

    return code.get('status');
  }

  pull() {
    return this.triage(true);
  }

  pullNoLog() {
    return this.triage(false);
  }

  async pushMedia() {
    this.inProgress = true;
    var reader = new FileReader();
    let file = new Parse.File('video.mp4', new File([this.videoFile], 'video.mp4'));
    await file.save({
      progress: (progressValue, loaded, total, { type }) => {
        if (type === "upload" && progressValue !== null) {
          // Update the UI using progressValue
          progression.value = Math.round(progressValue * 100)
        }
      }
    });

    let message = new Message();
    message.set('contentFile', file);
    message.set('code', this.codeObject);
    message.set('content', this.message);
    message.set('language', WEBSITE.currentLanguage.languageObject);
    console.log('this.planification', this.planification)
    if (livstickApp.planification) message.set('scheduledAt', new Date(this.planification.valueOf()));
    if (livstickApp.answer) message.set('answeredTo', this.messageObject)
    message.set('data', {
      ...{
        receiverEmail: this.email,
        senderEmail: this.senderEmail,
        receiverPhone: this.phone,
        messageNotified: this.phone ? "sms" : "email",
        firstname: this.firstname,
        lastname: this.lastname,
      }, ...this.additionalData
    });

    await message.save();
    // if (livstickApp.answer) {
    //   this.messageObject.set('answeredBy', message);
    //   await this.messageObject.save();
    // } else {
    //   this.messageObject = message;
    // }
    this.uploaded = true;
    this.inProgress = false;
  }

  getMedia() {
    axios({
      method: 'GET',
      url: `/liv3/pull_text.php?code=${this.code}`,
    });
  }


  async checkCodeFormat() {
    const contextPlatform = WEBSITE.platform;

    if (contextPlatform.get("services").includes("front_code_validator")) {

      const { codeFormat } = contextPlatform.get("servicesSettings");

      return codeFormat;
    }

  }

}


let livstickApp = new LivstickApp();

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

if (urlParams.get('utm_source') != null || urlParams.get('utm_medium') != null || urlParams.get('utm_campaign') != null) {
  livstickApp.UTM = true;
  livstickApp.UTM_source = urlParams.get('utm_source');
  livstickApp.UTM_medium = urlParams.get('utm_medium');
  livstickApp.utm_campaign = urlParams.get('utm_campaign');
}

export default livstickApp;



