import React from "react";
import { useIntl } from "react-intl";
import livstickApp from "../../../models/livstick";
import { Checkbox, CheckboxGroup, CheckboxLabel } from '../../../widgets/forms';

const MessageCheckbox:React.FunctionComponent<{onChange?(value: boolean): void, translationId: string, consentKey:string }> = ({onChange, translationId, consentKey}) => {
  const intl = useIntl();

  const [isToggled, setIsToggled] = React.useState(false)

  const toggle = () => {
    const newValue = !isToggled;
    if (onChange) onChange(newValue);
    console.log("MessageCheckbox", consentKey)
    livstickApp.additionalData[consentKey] = newValue;

    setIsToggled(newValue)
  }

  return <CheckboxGroup onClick={toggle} active={isToggled == true}>
    <Checkbox/>
    <CheckboxLabel  dangerouslySetInnerHTML={{__html:intl.formatMessage({id: translationId})}}></CheckboxLabel>
  </CheckboxGroup>
}

export default MessageCheckbox;